const { $, $$ } = window // eslint-disable-line

export class ProgressBar {
  constructor(container, max, current, bar) {
    this.container = container
    this.max = max
    this.current = current
    this.bar = bar
    this.addEventListeners()
  }

  moveProgressBar = () => {
    const getPercent = Math.max(this.current / this.max + 0.009, 0.04)
    const getProgressWrapWidth = this.container.offsetWidth
    const progressTotal = getPercent * getProgressWrapWidth
    this.bar.style.width = `${progressTotal}px`

    if (this.max === this.current) {
      this.container.classList.add("progress-maxed")
    }
  }

  addEventListeners = () => {
    document.addEventListener("DOMContentLoaded", this.moveProgressBar)
    window.addEventListener("load", this.moveProgressBar)
    window.addEventListener("resize", () => {
      this.moveProgressBar()
    })
  }
}
