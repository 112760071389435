const refreshCaptchaToken = (form) => {
  return new Promise((resolve, reject) => {
    const input = form.querySelector("input.g-recaptcha")
    const captchaSiteKey = input.dataset.sitekey

    return grecaptcha.execute(captchaSiteKey).then(
      (token) => {
        input.value = token
        console.log("Recaptcha token successfully refreshed.")
        resolve(form)
      },
      (v) => reject(v),
    )
  })
}

const initRecaptchaAutorefresh = () => {
  const inputs = document.querySelectorAll(
    "form:not([data-disable-recaptcha-token-autoreload]) input.g-recaptcha",
  )

  Array.prototype.slice.call(inputs).forEach((input) => {
    const form = input.closest("form")

    form.addEventListener("submit", (e) => {
      e.preventDefault()
      refreshCaptchaToken(form).then((form) => form.submit())
    })
  })
}

export { initRecaptchaAutorefresh, refreshCaptchaToken }
