import "./app/main.scss"
import { initRecaptchaAutorefresh } from "./app/js/captcha"
import initSignatureForms from "./app/js/signatureForm"

import "blissfuljs"
import _throttle from "lodash/throttle"

import BigPicture from "bigpicture"
import { Accordion } from "./app/js/accordion"
import { setDetailsHeights } from "./app/js/details.js"
import { Gallery } from "./app/js/gallery"
import { Letter } from "./app/js/letter"
import { Navigation } from "./app/js/navigation"
import { ProgressBar } from "./app/js/progressbar"
import { ToggleElements } from "./app/js/toggleElements"

import { initInaturalist } from "./app/js/inaturalist.js"
import { getCookie } from "./app/js/utils"

const $ = window.Bliss
const $$ = $.$

$.ready().then(() => {
  initRecaptchaAutorefresh()
  initSignatureForms()
  setDetailsHeights("details")

  // Mobile Nav Toggle
  new Navigation()

  new ToggleElements()

  initInaturalist()

  const letterForm = $("[data-letter-form]")
  const letterViewer = $("[data-letter-viewer]")

  if (letterForm) {
    new Letter(letterForm, letterViewer)
  }

  const appear = document.querySelectorAll(".appear")

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.style.animation = `${entry.target.dataset.appear} ${entry.target.dataset.speed} ${entry.target.dataset.delay} forwards ease-out`
      } else {
        // entry.target.style.animation = 'none';
      }
    })
  })

  appear.forEach((entry) => {
    observer.observe(entry)
  })

  const accordion = $$("[data-accordion-id]")
  if (accordion) {
    $$("[data-accordion-id]").forEach((el) => new Accordion(el))
  }

  const scrolledThreshold = 120

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
      } else {
        document.body.classList.remove("scrolled")
      }
    }, 100),
    { passive: true },
  )

  const input = $$(".wrapper__number")

  input.forEach((e) => {
    const btn_add = e.querySelector(".add")
    const btn_remove = e.querySelector(".remove")
    const inputValRaw = e.querySelector("input")

    function updateInput(v) {
      inputValRaw.value = v
    }

    inputValRaw.addEventListener("change", () => {
      const input_val = Number.parseInt(inputValRaw.value, 10)
      if (input_val > inputValRaw.max) {
        inputValRaw.value = inputValRaw.max
      }
    })
    btn_remove.addEventListener("click", () => {
      let input_val = Number.parseInt(inputValRaw.value, 10)
      if (input_val > inputValRaw.min) {
        input_val--
        updateInput(input_val)
      }
    })
    btn_add.addEventListener("click", () => {
      let input_val = Number.parseInt(inputValRaw.value, 10)
      if (input_val < inputValRaw.max) {
        input_val++
        updateInput(input_val)
      }
    })
  })

  // form

  const formInput = $$(".form-fieldset > input")

  if (input) {
    window.addEventListener("change", () => {
      formInput.forEach((e) => {
        if (!e.value === "") {
          e.classList.add("filled")
        } else {
          e.classList.remove("filled")
        }
      })
    })

    formInput.forEach((e) => {
      e.setAttribute("tabindex", 0)
      if (!e.value === "") {
        e.classList.add("filled")
      } else {
        e.classList.remove("filled")
      }
    })
  }

  // ProgressBar

  $$("[data-progress]").forEach(
    (el) =>
      new ProgressBar(
        el,
        el.getAttribute("data-progress-max"),
        el.getAttribute("data-progress-current"),
        el.querySelector("[data-progress-bar]"),
      ),
  )
})

window.addEventListener("load", () => {
  const submissionName = $("[data-submission-name]")

  if (submissionName) {
    submissionName.innerHTML = getCookie("id_given_name")
  }

  // river

  const riverPath = $("#riverPath")
  const intro = $("#intro")
  // let riverTreshold = $("[data-treshold]")

  if (intro) {
    let riverPathHeight = riverPath.clientHeight
    intro.style.height = `${riverPathHeight}px`
    window.addEventListener("resize", () => {
      riverPathHeight = riverPath.clientHeight
      intro.style.height = `${riverPathHeight}px`
    })
  }

  const projectGalleries = $$(".media-slider__slide:not(.gallery--none)")
  const factSlider = $$(".factslider__slide")

  if (projectGalleries) {
    projectGalleries.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }
  if (factSlider) {
    factSlider.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }

  // let scrollOptions = {
  //   duration: 600,
  //   activeOffset: 160,
  //   scrollOffset: 120,
  // }

  const videoPopup = document.querySelectorAll("[data-video-popup]")

  function openVideo(e) {
    BigPicture({
      el: e.target,
      vimeoSrc: e.target.dataset.vimeosrc,
    })
  }
  if (videoPopup) {
    videoPopup.forEach((video) => {
      video.addEventListener("click", (e) => {
        e.preventDefault()
        openVideo(e)
      })
    })
  }
})
