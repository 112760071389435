import { render } from "preact"

export const initInaturalist = async () => {
  const appEls = document.querySelectorAll(".inat-widget")

  for (const appEl of appEls) {
    const data = await fetch(appEl.dataset.endpoint)
    render(<App items={await data.json()} />, appEl)
  }
}

const App = ({ items }) => {
  return (
    <div class="grid-container grid-container--margin">
      <div class="grid-x grid-padding-x grid-padding-y">
        {items.reverse().map((item) => Item({ ...item }))}
      </div>
    </div>
  )
}

const Item = (item) => {
  const photo = item.photos[0]
  const user = item.user

  return (
    <div
      key={item.id}
      class="cell small-6 medium-4 large-3 [ appear appear-fade-in-up ]"
      data-speed="0.8s"
      data-appear="fadeUp"
      data-delay="0.0s"
      style="animation: 0.8s ease-out forwards fadeUp;"
    >
      <article class="article-teaser__element ">
        <a href={item.uri} target="_blank" rel="noreferrer noopener">
          <div class="article-teaser__image-wrapper">
            <img class="article-teaser__image" src={photo.medium_url} alt="" />
          </div>
        </a>
        <div class="article-teaser__wrapper">
          <div class="article-teaser-content">
            <h3>
              <span class="">
                {item.species_guess}
                <br />({item.taxon.name})
              </span>
            </h3>
            <p>
              <span class="inat-widget__user">
                von{" "}
                <img
                  class="inat-widget__user-icon"
                  src={user.user_icon_url}
                  alt=""
                />{" "}
                {user.login}
              </span>
            </p>
          </div>
        </div>
      </article>
    </div>
  )
}
